

.homeBackgroundImage1 {
  background-image: url(../public/beautiful-natural-landscape-forest.jpg);
}


.homeBackgroundImage2 {
  background-image: url(../public/garden_winter.jpg);
}

.homeBackgroundImage3 {
  background-image: url(../public/background1.jpg);
}

.text-stroke {
  -webkit-text-stroke : 1px #444;
}

.font-color-green {
  color : #166939
}

.OliveMapBtn {
  width: 90px;
  background-color: #fff;
  border: 1px solid #444;
  padding: 10px;
  color: #444;
  font-size : 1.1rem;
  transition: all 300ms ease-in-out;
}

.BtnSelected {
  width: 90px;
  background-color: #444;
  color : #fff;
  border: 1px solid #444;
  padding: 10px;
  font-size : 1.1rem;
  transition: all 300ms ease-in-out;
  font-weight: 600;
}

.OliveMapBtn:hover {
  background-color: #444;
  color : #fff;
  font-weight: 600;
}

.whiteBg {
  background-color: #fff;
  color : #000;
}

.grayBg {
  background-color :rgb(170, 170, 170);
  color : #fff;
}

.transition300 {
  transition: all 300ms ease-in-out;
}

.hrStyle {
  width: 180px;
  border-top-width: 2px;
  border-color: rgba(0, 0, 0, 0.2);
}

.pointSerif {
  font-family: 'Italiana', serif;
}


.menu[class*=" p-"] li > *,
.menu[class^="p-"] li > * 
{
  border-radius: 0;
}

.DragParent {
  width: 100%;
  /*height:100%;*/
  height:  calc(100% + 5rem);
}

.keepAll {
  word-break: keep-all;
}

.steps .step:after {
  width: 25px;
  height: 25px;
}

@-webkit-keyframes coffee {
  100% {
    opacity: 0;
    -webkit-transform: translateY(-200%);
            transform: translateY(-200%); } }
@keyframes coffee {
  100% {
    opacity: 0;
    -webkit-transform: translateY(-200%);
            transform: translateY(-200%); } }
            
.coffee {
  background: #444;
  display: grid;
  border-radius: 4px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  height: 40px;
  position: relative;
  width: 40px;
  padding: 2px;
  justify-items: center; }
  .coffee:before {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    content: '';
    height: 26px;
    width: 20px;
    border: 4px solid #444;
    position: absolute;
    top: 50%;
    margin-top: -13px;
    margin-left: -10px;
    left: 100%;
    border-radius: 4px; }
  .coffee div {
    height: 20px;
    width: 8px;
    background: #444;
    border-radius: 50%;
    opacity: .3;
    -webkit-animation: coffee 2s infinite;
            animation: coffee 2s infinite; }
  .coffee div:nth-child(1) {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s; }
  .coffee div:nth-child(2) {
    -webkit-animation-delay: 1s;
            animation-delay: 1s; }
  .coffee div:nth-child(3) {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s; }


.coffeeAnimation {
  animation: coffee 300 ;
}

.marqueeAnimation {
  -webkit-animation: marquee 10s linear infinite;
  -moz-animation:marquee 10s linear infinite;
  -o-animation:marquee 10s linear infinite;
  -ms-animation: marquee 10s linear infinite;
  animation: marquee 10s linear infinite;
}

  @keyframes marquee {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(100%);
    }
  }
  @-webkit-keyframes marquee {
     from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(100%);
    }
  }
  @-moz-keyframes marquee {
     from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(100%);
    }
  }
  @-o-keyframes marquee {
     from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(100%);
    }
  }
  @-ms-keyframes marquee {
     from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(100%);
    }
  }

